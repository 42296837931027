import * as React from "react"
import { graphql } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"
import { useForm } from "react-hook-form"
import axios from "axios"

import Seo from "../components/seo"
import Layout from "../components/layout"

const seoTitle = "お問い合わせ｜ TANOSHIGE【たのしげ】"
const seoDescription = "「TANOSHIGE」サイトで取り扱う個人情報をにおいて「個人情報の保護に関する法律」をはじめとする個人情報の保護に関する法令、ガイドラインおよび本プライバシーポリシーを遵守いたします。"

// markup
const ContactPage = ({ data }) => {
  const { register, handleSubmit, formState: { errors } } = useForm()
  const onSubmit = (data) => { 
    data.aizu = "tanoshig"

    axios.post('https://m6y5gav645.execute-api.ap-northeast-1.amazonaws.com/ms8c4p-contact', data, {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*'
    })
      .then((res) => {
        let contactInner = document.querySelector('.contact-inner')
        let contactThanks = document.querySelector('.contact-thanks')
    
        contactInner.classList.add('ui-hidden')
        contactThanks.classList.remove('ui-hidden')
      }, (error) => {
      })
  }

  return (
    <Layout pageSlug="contact" isHomePage={true} headerClass="bg-white" isLogoOneWhite={false} isLogoTwoWhite={false}>
      <Seo title={seoTitle} description={seoDescription} />
      <main id="contact">
        <h2><StaticImage src="../images/ttl-contact.png" alt="" /></h2>
        <section className="contact-inner">

          <form onSubmit={handleSubmit(onSubmit)}>
            <input type="text" name="company" id="company" placeholder="企業名" {...register("company")} />
            <input type="text" name="name" id="name" placeholder="お名前" {...register("name", { required: true })} />
            {errors.name?.type === 'required' && <p className="err">お名前を入力してください</p>}
            <input type="email" name="email" id="email" placeholder="メールアドレス" {...register("email", { required: true })} />
            {errors.email?.type === 'required' && <p className="err">メールアドレスを入力してください</p>}
            <input type="tel" name="tel" id="tel" placeholder="お電話番号" {...register("tel")} />
            <textarea name="inquiry" name="inquiry" id="inquiry" cols="30" rows="10" placeholder="お問い合わせ内容" {...register("inquiry")}></textarea>
            <button type="submit" className="link-btn"><span>送信する</span></button>
          </form>
        </section>

        <section className="contact-thanks ui-hidden">
          <h3>お問い合わせが完了いたしました。</h3>
          <p>お問い合わせいただきありがとうございました。<br />
          お問い合わせを受け付けました。</p>

          <p>折り返し、担当者よりご連絡いたしますので、恐れ入りますが、しばらくお待ちください。</p>

          <p>なお、ご入力いただいたメールアドレス宛に受付完了メールを配信しております。<br />
          完了メールが届かない場合、処理が正常に行われていない可能性があります。<br />
          大変お手数ですが、再度お問い合わせの手続きをお願い致します。</p>    
        </section>
      </main>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allWpNews(sort: { fields: [date], order: DESC }) {
      totalCount
      edges {
        node {
          id
          uri
          title
          slug
          date(formatString: "Do MMMM")
        }
      }
    }
  }
`

export default ContactPage
